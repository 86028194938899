@import url('https://fonts.googleapis.com/css2?family=Lora:ital@0;1&family=Nunito:wght@200;400&display=swap');

body {
    margin: 0;
    font-family: 'Lora', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: whitesmoke;
}

code {
    font-family: 'Nunito', sans-serif;
}

h1, h2, h3, h4, h5, h6, p, a, li {
    color: #A59085;
}

h1, h3, h4, h5 {
    font-family: 'Nunito', sans-serif;
}

h3, h6 {
    font-family: 'Lora', serif;
}

p, a, li {
    font-family: 'Lora', serif;
    font-size: 1.063rem;
}

h1 {
    font-size: 2.125rem;
}

h2 {
    font-size: 1.75rem;
}

h3 {
    font-size: 1.375rem;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-size: 1.063rem;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
    p, a, li {
        font-size: 1.5rem;
    }

    h1 {
        font-size: 6rem;
    }

    h2 {
        font-size: 3.75rem;
    }

    h3 {
        font-size: 3rem;
    }

    h4 {
        font-size: 2.125rem;
    }

    h5 {
        font-size: 1.5rem;
    }
}

@media (min-width: 1400px) {

}