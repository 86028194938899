.App {
    text-align: center;
}

.Landing-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .Landing-logo {
        animation: App-logo-spin infinite 5s ease-in-out;
    }
}

.Landing-header {
    background-color: #ffffff;
    background-image: url("./pegasus_background_header.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 240px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
}

.Landing-header_text {
    padding: 480px 0;
}

.App-link {
    color: #61dafb;
}

.Text-white {
    color: #A59085;
    background-color: rgba(250, 250, 250, 0.7);
    padding: 20px 40px;
    border: 3px solid #A59085;
    border-radius: 5px;
    margin: 80px 20px 0 20px;
}

.Container {
    width: 95%;
    margin: 0 auto;
}

.Landing-break {
    background-color: #CBE9F7;
    padding: 20px 0;
    font-style: italic;
}

.Landing-info {
    padding: 80px 0 160px 0;
}

.Landing-info_list-wrapper {
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*flex-direction: column;*/
}

.Landing-info_list {
    padding: 20px 0;
    /*list-style: none;*/
    /*display: flex;*/
    /*align-items: flex-start;*/
    /*justify-content: flex-start;*/
    /*flex-direction: column;*/
}

.Landing-info_list li {
    padding: 5px 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: start;
}

.Link-Impressum {
    padding: 20px 0;
    cursor: pointer;
}

.Container {
    width: 80%;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .Container {
        width: 60%;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
